$env: production;
@import '@/styles/_component-base.styles.scss';

.quiz {
    display: block;
    margin: auto;
    width: 100%;
    height: 600px;

    @include breakpoint(sm) {
        max-width: none;
        width: 400px;
    }

    @include breakpoint(md) {
        width: 500px;
    }

    @include breakpoint(lg) {
        width: 555px;
    }

    @include breakpoint(xl) {
        width: 100%;
        height: 700px;
    }

    &AnswerResults {
        height: 1740px;

        @include breakpoint(md){
            height: 1900px;
        }

        @include breakpoint(xl){
            height: 2100px;
        }
    }

    &Completed {
        height: 1300px;

        @include breakpoint(xl){
            height: 1500px;
        }
    }
}

.quizContainer {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @include responsive-dpi-imgix('/quiz/jebbit-background', 'jebbit-background', 375, 768, 1440, true);

    padding: spacer(base);

    @include breakpoint(sm) {
        padding: spacer(md) 0;
    }

    @include breakpoint(md) {
        padding: spacer(lg) 0;
    }

    @include breakpoint(lg) {
        padding: spacer(xl) 0;
    }

    @include breakpoint(xl) {
        padding: 0;
    }
}

.section {
    padding: 0;

    @include breakpoint(sm) {
        padding: spacer(md) 0;
    }

    @include breakpoint(md) {
        padding: spacer(lg) 0;
    }
}
